import { usePrivy } from '@privy-io/react-auth';
import { useQueryClient } from '@tanstack/react-query';
import { UserAuthenticationState } from '@trustblock/types/user.types';
import { resetPosthog } from '@trustblock/web/helpers/posthog';
import { useState } from 'react';
import useGetUser from './useGetUser';

export default function useAuth() {
  const { login, logout, isModalOpen, ready: hasPrivyRun } = usePrivy();
  const {
    query: { data, isLoading, error, refetch, isFetched },
    isPrivyConnected
  } = useGetUser();
  const [isLogoutLoading, setIsLogoutLoading] = useState(false);
  const isAuthenticating = isModalOpen || isLoading;
  const queryClient = useQueryClient();
  let hasAuthenticationRun = false;
  if (isPrivyConnected) {
    hasAuthenticationRun = isFetched && !isAuthenticating;
  } else {
    hasAuthenticationRun = hasPrivyRun && !isAuthenticating;
  }
  const { privyUser, user } = data || {};
  let userAuthenticationState = UserAuthenticationState.Visitor;
  if (privyUser && !user) {
    userAuthenticationState = UserAuthenticationState.ConnectedWithoutUser;
  } else if (privyUser && user) {
    userAuthenticationState = UserAuthenticationState.SignedIn;
  }

  const localLogout = async () => {
    setIsLogoutLoading(true);
    await logout();
    resetPosthog();
    queryClient.removeQueries({ queryKey: ['me'] });
    setIsLogoutLoading(false);
  };

  return {
    userAuthenticationState,
    login,
    logout: localLogout,
    authUser: privyUser,
    user,
    error,
    isAuthenticating,
    isLogoutLoading,
    hasAuthenticationRun,
    refetchUser: refetch
  };
}
