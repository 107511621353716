import { usePrivy } from '@privy-io/react-auth';
import { skipToken, useQuery } from '@tanstack/react-query';
import { apiRoutes } from '@trustblock/web/helpers/api';
import useApi from '@trustblock/web/hooks/useApi';

export default function useGetUser() {
  const { authenticated, ready, user: privyUser } = usePrivy();
  const request = useApi();
  const isPrivyConnected = authenticated && ready && !!privyUser;

  const getUser = isPrivyConnected
    ? async ({ signal }: { signal: AbortSignal }) => {
        const response = await request<'getUser'>({
          route: apiRoutes.getUser(),
          withAuthentication: true,
          signal
        });

        if (response.isError) {
          // We have an issue with fetch returning a 403 that is not being correctly handled and throws an error instead of returning. We should have access to status here.
          return { privyUser };
        }

        return { privyUser, user: response.data };
      }
    : skipToken;

  return {
    isPrivyConnected,
    query: useQuery({
      queryKey: ['user', privyUser?.id],
      queryFn: getUser,
      staleTime: 5000,
      refetchOnWindowFocus: false
    })
  };
}
