import { usePrivy } from '@privy-io/react-auth';
import { type ApiRoutesData, type apiRoutes, requestApi } from '@trustblock/web/helpers/api';

function useApi() {
  const { getAccessToken } = usePrivy();

  return async <K extends keyof typeof apiRoutes>({
    route,
    data,
    withAuthentication = false,
    extraHeaders,
    signal
  }: {
    route: ReturnType<(typeof apiRoutes)[K]>;
    data?: ApiRoutesData[K];
    withAuthentication?: boolean;
    extraHeaders?: Record<string, string>;
    signal?: AbortSignal;
  }) => {
    const finalAccessToken = withAuthentication ? await getAccessToken() : undefined;
    const headers = {
      ...extraHeaders
    };

    if (withAuthentication) {
      headers.Authorization = `Bearer ${finalAccessToken}`;
    }

    return requestApi<K>({
      route,
      data,
      headers,
      signal
    });
  };
}

export default useApi;
