import environment from '@trustblock/constants/environment.constants';
import posthog from 'posthog-js';

export const events = {
  auditRequest: 'Audit Request'
} as const;

type Event = (typeof events)[keyof typeof events];
type EventsKeys = keyof typeof events;

interface EventParams {
  auditRequest: { auditorSlug: string };
}

export type EventParamsReturnTypes = {
  [K in keyof typeof events]: EventParams[K];
};

const isProduction = environment.WEB_URL === 'https://app.trustblock.run';

export function initPosthog() {
  if (!isProduction) return;
  posthog.init(environment.POSTHOG_API_KEY, {
    api_host: environment.POSTHOG_HOST
  });
}

export function resetPosthog() {
  if (!isProduction) return;
  posthog.reset();
}

export default function sendEvent<T extends EventsKeys>(
  event: Event,
  data: {
    distinctId?: string;
    eventParams?: EventParamsReturnTypes[T];
  }
) {
  if (!isProduction) return;

  const eventProperties = {
    ...data.eventParams,
    env: 'WEB'
  };
  if (data.distinctId) {
    posthog.identify(data.distinctId);
  }

  posthog.capture(`[WEB] ${event}`, eventProperties);
}
